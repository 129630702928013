import React from 'react'
import moment, { isMoment } from 'moment'
import { FormattedNumber } from 'react-intl'
import round from 'lodash/round'
import { warnDeprecation } from 'helpers/deprecation'
import { Translation } from 'react-i18next'
import { t } from 'services/i18n'
import { PLAN_CODE_LABELS } from 'constants/admin/shared'
import { CONSOLIDATED_PLAN_NAME } from 'constants/admin'
import { isValidValue } from './values'

export const dummyFormatter = value => value

/**
 * Converts a number to currency format
 * with specified currency code and no decimals.
 *
 * @deprecated in favor of formatCurrency and formatCurrencyWithCode
 *
 * @param {array} value - number to format
 * @param {array} currency - currency code
 */
export const humanizePriceFormatter = (value, currency) => {
  warnDeprecation(
    'Please use formatCurrency or formatCurrencyWithCode instead.'
  )

  if (!value) return null
  return parseFloat(value).toLocaleString('en-US', {
    style: 'currency',
    currency: currency || 'USD',
    minimumFractionDigits: 0
  })
}

/**
 * Converts a number to currency format
 * with specified currency code and decimals.
 *
 * @deprecated in favor of formatCurrency and formatCurrencyWithCode
 *
 * @param {array} value - number to format
 * @param {array} currency - currency code
 */
export const humanizePriceFormatterWithDecimals = (value, currency) => {
  warnDeprecation(
    'Please use formatCurrency or formatCurrencyWithCode instead.'
  )

  if (!value && value !== 0) return null

  return parseFloat(value).toLocaleString('en-US', {
    style: 'currency',
    currency: currency || 'USD',
    minimumFractionDigits: 2
  })
}

export const humanizeBooleanFormatter = value => {
  if (!value) return 'No'
  return 'Yes'
}

export const humanizedDateFromEpoch = (value, format = 'MMM DD, YYYY') => {
  if (!value) {
    return null
  }

  try {
    return moment(value)
      .utc()
      .format(format)
  } catch (ex) {
    return typeof value === 'string' ? value : null
  }
}

// formats strings like "2018-12-11 21:21:50" to "December 11, 2018"
export const formatStringDate = value => {
  if (!value) return null
  const dateFormatOptions = { month: 'long', day: 'numeric', year: 'numeric' }
  const date = new Date(value)
  return date ? date.toLocaleDateString('en-US', dateFormatOptions) : null
}

// Given a date
// E.g. 2018-12-11
// December 2018
export const dateToYearMonth = date => {
  return moment(date).format('MMMM YYYY')
}

// Shortens a long number
// so 1,324,231 => 1.32m
// or 1,983 => 1.9k
export const humanizeLongNumber = (val, roundTo = 2) => {
  const positiveVal = Math.abs(val)

  if (positiveVal >= 1000000) {
    return `${round(val / 1000000, roundTo)}m`
  }
  if (positiveVal >= 1000) {
    return `${round(val / 1000, roundTo)}k`
  }
  return val
}

export const humanizeLongNumberWithLocale = (
  number,
  locale,
  minDigits,
  maxDigits
) => {
  try {
    // return Number(number).toLocaleString(locale)
    if (!Number.isInteger(minDigits)) {
      // If it has decimal values, enforce the rule of 1 minimumFractionDigits
      if (number % 1 === 0) {
        minDigits = 0
      } else {
        minDigits = 2
      }
    }

    locale = locale || navigator.userLanguage || navigator.language
    return Number(number.toFixed(2)).toLocaleString(locale, {
      minimumFractionDigits: minDigits,
      maximumFractionDigits: Number.isInteger(maxDigits) ? maxDigits : 2
    })
  } catch (e) {
    return number
  }
}

export const linkFormatter = (route, display = null, options = {}) => {
  return (
    <a href={route} {...options}>
      {display || route}
    </a>
  )
}

export const dimensionFormatter = ({
  length,
  width,
  height,
  dimensionUnit,
  redesign
}) => {
  if (isValidValue(length) && isValidValue(width) && isValidValue(height)) {
    const unit =
      !dimensionUnit || dimensionUnit === 'inches'
        ? '"'
        : dimensionUnit.match(/(c|z)enti/i)
        ? 'cm'
        : dimensionUnit
    length = parseFloat(length).toFixed(2)
    width = parseFloat(width).toFixed(2)
    height = parseFloat(height).toFixed(2)
    return `${length}${unit} x ${width}${unit} x ${height}${unit}`
  }
  return redesign ? '-' : 'N.A.'
}

export const weightFormatter = ({ weight, weightUnit, redesign }) => {
  if (isValidValue(weight)) {
    const unit =
      !weightUnit || weightUnit === 'pounds'
        ? 'lbs'
        : weightUnit.match(/kilo/i)
        ? 'kg'
        : weightUnit
    weight = parseFloat(weight).toFixed(2)
    return `${weight} ${unit}`
  }
  return redesign ? '-' : 'N.A.'
}

export const simpleValueFormatter = value => {
  if (value) {
    if (typeof value === 'string') {
      return value !== 'undefined' && value.trim() ? value : 'N.A.'
    }
    return value
  }
  return 'N.A.'
}

export const roundNumberFormatter = value => {
  if (value) {
    if (typeof value === 'string') {
      return value !== 'undefined' && value.trim() ? value : 'N.A.'
    }
    return parseInt(value, 10).toLocaleString()
  }
  return 'N.A.'
}

export const percentageFormatter = value => {
  return value ? `${value}%` : ''
}

export const formatDays = days => {
  return (
    <Translation ns="generic">
      {t => t('generic:days', '{{ count }} Day', { count: days })}
    </Translation>
  )
}

export const noneNoValue = value =>
  value == null || value === '' ? 'None' : value

export const productCodeFormatter = code => PLAN_CODE_LABELS[code]

export const durationFormatter = (
  duration,
  { hide_seconds = false, full_display = false } = {}
) => {
  const seconds = duration % 60
  const minutes = Math.floor(duration / 60) % 60
  const hours = Math.floor(duration / 60 / 60)
  const values = []

  if (duration < 1 && !full_display) {
    return hide_seconds ? '0m' : '0s'
  }
  if (duration < 60 && hide_seconds && !full_display) {
    return '1m'
  }

  if (hours > 0 || full_display) {
    values.push(`${hours}h`)
  }

  if (minutes > 0 || full_display) {
    values.push(`${minutes}m`)
  }

  if (hours < 1 && seconds > 0 && !hide_seconds) {
    values.push(`${seconds}s`)
  }

  return values.join(' ')
}

export const durationFormatterColon = duration => {
  const seconds = duration % 60
  const minutes = Math.floor(duration / 60) % 60
  const hours = Math.floor(duration / 60 / 60)
  const values = []

  // Push hours if there are any
  if (hours > 0) {
    values.push(hours)
  }

  values.push(minutes, seconds)

  // Pad all values except the first, so 1 becomes 01
  return values
    .map((val, index) => (index === 0 ? val : String(val).padStart(2, '0')))
    .join(':')
}

export const normalizedNameFormatter = name => {
  return [CONSOLIDATED_PLAN_NAME].includes(name)
    ? 'Jungle Scout Plan'
    : `${name} Plan`
}

export const titleCase = str => {
  if (typeof str !== 'string') {
    return ''
  }

  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.replace(word[0], word[0]?.toUpperCase()))
    .join(' ')
}

export const snakeToCamel = str => {
  return str.replace(/([-_][a-z])/g, group =>
    group
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
  )
}

export const isNumberOrNumericString = value =>
  !Number.isNaN(value) && !Number.isNaN(parseFloat(value))

export const toNumeric = value => {
  if (typeof value === 'number') {
    return value
  }

  return isNumberOrNumericString(value) ? parseFloat(value) : null // explicitly returning null here as function is used in Segment events
}

export const toInteger = value => {
  const parsedNumber = Number.parseInt(value, 10)

  if (Number.isNaN(parsedNumber)) {
    return null
  }

  return parsedNumber
}

export const formatVolume = (exactSearchVolume, format = true) => {
  // Our floor estimates are 450 (Amazon limitation). So anything equal or below 450 gets displayed as '< 450'
  const floor = 450

  const parsedVolume = parseInt(Number(exactSearchVolume), 10)
  const noData = exactSearchVolume === undefined || Number.isNaN(parsedVolume)

  if (noData || parsedVolume <= 5) {
    return t(
      'rank_tracker:rankTrackerV2.RankConfiguration.KeywordBank.lowVolume',
      'Low'
    )
  }

  if (parsedVolume <= floor) {
    return `< ${floor}`
  }

  return format ? <FormattedNumber value={parsedVolume} /> : parsedVolume
}

// rank tracker specific format for search volume
export const formatRankVolume = (exactSearchVolume, format = true) => {
  const floor = 450

  const parsedVolume = parseInt(Number(exactSearchVolume), 10)
  const noData = exactSearchVolume === undefined || Number.isNaN(parsedVolume)

  if (noData || parsedVolume <= 5) {
    return `< 5`
  }

  if (parsedVolume <= floor) {
    return `< ${floor}`
  }

  return format ? <FormattedNumber value={parsedVolume} /> : parsedVolume
}

export const dateWithMonthName = val => {
  return (isMoment(val) ? val : moment(val, 'MM/DD/YYYY')).format(
    'MMMM DD, YYYY'
  )
}

export const cleanNumber = value => {
  if (!value) {
    return 0
  }

  const rawValue = String(value)
  const string = rawValue.replace(/[^0-9.,]/g, '')
  const number = parseFloat(string)
  return Number.isNaN(number) ? 0 : number
}
